import { useCallback, useEffect, useState } from 'react'
import useModelFunction from '../../store/useModelFunction'

const useHasPhoto = (user_id) => {
  const [isPhotoAvailable, state] = useModelFunction('user', 'isPhotoAvailable')
  const [hasPhoto, setHasPhoto] = useState(false)

  const isPhotoAvailableHandler = useCallback(async () => {
    const response = await isPhotoAvailable({ user_id })
    setHasPhoto(response?.status)
    return response
  }, [user_id])

  useEffect(() => {
    if (!!user_id)
      isPhotoAvailableHandler()
  }, [user_id])

  return [hasPhoto, isPhotoAvailableHandler, state]
}

export default useHasPhoto

