import { useHasPhoto, useUploadPhoto, useDeletePhoto, usePhotoURL } from './index'

const useHandlePhoto = (user_id) => {
  const [hasPhoto, getHasPhoto, hasPhotoState] = useHasPhoto(user_id)
  const [uploadPhoto, uploadPhotoState] = useUploadPhoto(user_id)
  const [photoURL] = usePhotoURL(user_id, hasPhoto)
  const [deletePhoto, deletePhotoState] = useDeletePhoto(user_id)

  return [{ hasPhoto, photoURL }, { getHasPhoto, uploadPhoto, deletePhoto }, { hasPhotoState, uploadPhotoState, deletePhotoState }]
}

export default useHandlePhoto