import { useEffect, useCallback } from 'react'
import Utils from '../../../utils'
import _ from 'lodash'

const useGlobalKeyListener = (onKeyDown, keys, { disabled, ...control_keys } = {}) => {
  const execKeyDown = useCallback(e => {
    onKeyDown(e)
    e.preventDefault()
  }, [onKeyDown])

  const handleOnKeyDown = useCallback(event => {
    const { key, ctrlKey, altKey } = event
    if (
      _.includes(keys, key) && (
        (!!ctrlKey && !!control_keys?.ctrlKey) ||
        (!!altKey && !!control_keys?.altKey) ||
        (!ctrlKey && !altKey && !control_keys?.ctrlKey && !control_keys?.altKey)
      )
    )
      execKeyDown(event)
  }, [execKeyDown, Utils.selectAllKeys(control_keys, ['ctrlKey', 'altKey', 'disabled']).join(',')])

  useEffect(() => {
    if (!disabled) {
      window.addEventListener('keydown', handleOnKeyDown)
      return () => window.removeEventListener('keydown', handleOnKeyDown)
    }
    return _.noop
  }, [handleOnKeyDown])

  return
}

export default useGlobalKeyListener
