import { useCallback, useContext } from "react"
import _ from 'lodash'
import { ExternalContext } from "../../contexts/external"
import useMainContact from "./useMainContact"

const useFileDownload = (method, path, params, options) => {
  const contact = useMainContact()
  const { useBlobHandler } = useContext(ExternalContext)
  const handleBlob = useBlobHandler(path, params, options)
  const getBlob = useCallback(
    (request_params) => contact.execute(method, path, _.merge({}, params, request_params), {}, { axios_options: { responseType: 'blob' } }),
    [contact, method, path, params]
  )
  return useCallback((request_params) => handleBlob(getBlob.bind(null, request_params)), [handleBlob, getBlob])
}

export default useFileDownload