import { useCallback, useState } from "react"
import useMainContact from "../../relay/useMainContact"

const useUploadPhoto = (user_id) => {
  const [isLoading, setIsLoading] = useState(true)
  const contact = useMainContact()
  const uploadPhoto = useCallback(async (event) => {
    try {
      const file = event?.target?.files?.[0]
      const formData = new FormData()
      formData.append("file", file)

      return await contact.execute('POST', 'user/upload/photo', formData, {
        ['x-user-id']: user_id,
        ['Content-type']: 'multipart/form-data'
      })
    }
    catch (err) {
      console.error('Error while uploading photo', { err })
    }
    finally {
      setIsLoading(false)
    }
  }, [])

  return [uploadPhoto, { isLoading }]

}

export default useUploadPhoto