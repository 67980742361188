import _ from 'lodash'
import useModel from '../../store/useModel'

import useTranslation from '../translation/useTranslation'

const useTransportStatusList = () => {
  return ['planned', 'started', 'at_src', 'on_board', 'at_dst', 'off_board', 'complete', 'canceled']
}

const useTransportStatusColorList = () => {
  return {
    planned: '#E59400',
    started: '#0060AC',
    at_src: '#0060AC',
    on_board: '#0060AC',
    at_dst: '#0060AC',
    off_board: '#0060AC',
    complete: '#4CAF50',
    canceled: '#E21818'
  }
}
const useTransportStatusColor = transport_object => {
  const { transport_id } = transport_object || {}
  const [transport] = useModel('transport', [transport_id], { single: true })
  return useTransportStatusColorList()[_.get(transport, 'status')]
}

const useTransportStatus = (transport_object, { translated, load_type = 'user', ...options } = {}) => {
  const { transport_id } = transport_object || {}
  const [transport] = useModel('transport', [transport_id], { single: true, populate: ['reason'] })
  const status = transport?.status === 'canceled' && _.includes(['not_loaded', 'late_cancel'], transport?.reason?.identifier) ? transport?.reason?.identifier : transport?.status
  return useTranslation('transportStatus', status, {
    disabled: !translated,
    suffix: load_type,
    ...options,
  })
}

export {
  useTransportStatusColorList,
  useTransportStatusList,
  useTransportStatusColor,
  useTransportStatus,
}
