import { useCallback, useEffect, useState } from "react"

import useFileDownload from "../../relay/useFileDownload"

const usePhotoURL = (user_id, hasPhoto) => {
  const [photoURL, setPhotoURL] = useState()
  const downloadRecord = useFileDownload('GET', 'user/download/photo', { user_id }, { type: 'jpeg' })

  const getDownloadBlob = useCallback(async () => {
    try {
      if (hasPhoto) {
        const response = await downloadRecord({ user_id })
        setPhotoURL(response)
      }
    }
    catch (err) {
      console.error('Error while downloading photo', { err })
      setPhotoURL(null)
    }
  }, [downloadRecord, user_id, hasPhoto])

  useEffect(() => {
    getDownloadBlob()
  }, [user_id, hasPhoto])

  return [photoURL]

}

export default usePhotoURL