import { useCallback } from "react"

import useFileDownload from "../../relay/useFileDownload"

const useDownloadPhoto = (user_id) => {
  const downloadRecord = useFileDownload('GET', 'user/download/photo', {}, { type: 'jpeg' })
  const getDownloadBlob = useCallback(() => downloadRecord({ user_id }), [downloadRecord, user_id])

  return [getDownloadBlob]

}

export default useDownloadPhoto