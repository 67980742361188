import _ from 'lodash'
import useModel from '../../store/useModel'
import useTranslation from '../translation/useTranslation'

const useOrderPaymentMessage = (order_object, options) => {
  const { enableName = false, enableType = true, enableReason = false, ...rest_options } = options || {}
  const { order_id } = order_object || {}
  const [order] = useModel('order', [order_id], { single: true, populate: ['info', 'commercial_package', 'payment_type', 'reason'] })

  const order_reason = _.get(order, 'reason.identifier')
  const payment_type = _.get(order, 'payment_type.type')
  const payment_name = _.get(order, 'payment_type.name')

  return _.compact([
    !!enableName && payment_name,
    !!enableReason && useTranslation('payment_type', order_reason),
    !!enableType && useTranslation('payment_type', payment_type, rest_options)
  ]).join(', ')

}

export default useOrderPaymentMessage
