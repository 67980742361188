import { useCallback } from "react"
import useModelFunction from '../../store/useModelFunction'

const useDeletePhoto = (user_id) => {
  const [deletePhoto, state] = useModelFunction('user', 'deletePhoto')
  const deletePhotoHandler = useCallback(() => deletePhoto({ user_id }), [deletePhoto, user_id])

  return [deletePhotoHandler, state]

}

export default useDeletePhoto