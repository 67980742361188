import _ from "lodash"
import ProviderListener from ".."
const defaultTruth = () => true

class BasicListener extends ProviderListener {
  constructor(store, listener_id, info, options) {
    super(store, listener_id, info, options)
    this.related_info = []
    this.result = []
  }
  onCreate() {
    this.setIsLoading(!_.isEmpty(this.getRequestedIds()))
    return this._refresh()
  }
  onInfoUpdated() {
    this.setIsReady(false)
    return this._refresh()
  }
  onOptionsUpdated() {
    return this._refresh()
  }
  getRelatedInfo() {
    return this.related_info
  }
  getRequestInfo() {
    return _.compact(_.map(this.getRelatedInfo(), (info) =>
      _.isEmpty(info.missing_ids) && _.isEmpty(info.incomplete_ids) ? null : { model_name: info.model_name, record_ids: _.flatten([info.missing_ids, info.incomplete_ids]), populate: info.populate }
    ))
  }
  getRequestedIds() {
    if (this.getOption('allowAll'))
      return _.map(this.getParentProvider().getAllRecords(), this.getParentProvider().getPrimaryKey()) || []
    return _.uniq(_.compact(_.flatten([_.get(this.info, 'ids')]))) || []
  }
  refreshIfRelated(modified_data) {
    const has_match = _.find(modified_data, (modified_info) => {
      const matched_related_info = _.filter(this.getRelatedInfo(), ['model_name', modified_info.model_name])
      const matched_record_ids = _.flatten(_.map(matched_related_info, 'record_ids'))
      return !_.isEmpty(matched_related_info) && !_.isEmpty(_.intersection(matched_record_ids, modified_info.record_ids))
    })
    if (!!has_match || (this.getOption('allowAll') && _.includes(_.map(modified_data, 'model_name'), _.toLower(this.getParentProvider().getName()))))
      this._refresh()
  }
  _refreshRelatedInfo() {
    this.related_info = this.getParentProvider().getRelatedInfo(this.getRequestedIds(), this.getOption('populate'))
    return this
  }
  _refreshRequestInfo() {
    const request_info = this.getRequestInfo()
    if (!_.isEmpty(request_info)) {
      this.setIsLoading(true)
      this.getParentProvider().getProvider('fetch').requestRecords(request_info)
    }
    return this
  }
  forceRefresh() {
    this.getParentProvider().getProvider('fetch').requestRecords(this.getRelatedInfo())
    return this
  }
  _refresh() {
    this
      ._refreshRelatedInfo()
      ._refreshRequestInfo()
    const ids = this.getRequestedIds()
    const preFilter = this.getOption('preFilter') || defaultTruth
    const filtered_ids = _.map(_.filter(this.getParentProvider().getRecords(ids), preFilter), this.getParentProvider().getPrimaryKey())
    const populated_records = this.getParentProvider().getPopulatedRecords(filtered_ids, this.getOption('populate'))
    this.setResult(populated_records)
    if (_.isEmpty(this.getRequestInfo()))
      this.setIsLoading(false)
    return this
  }
}
export default BasicListener