import _ from "lodash"
import Contact from "../../contact"
import moment from "moment"
import Provider from '..'
import I18n from "../../../utils/translation"
class Relay extends Provider {
  constructor(id, root) {
    super(id, root)
    this.config = null
    this.device_info = null
    this.relay_contact = new Contact(this, 'relay', false, { use_provider_auth: false })
    this.main_contact = new Contact(this, 'main', false, { use_provider_auth: true })
    this.cdn_contact = new Contact(this, 'cdn', false, { use_provider_auth: false })
    this.identity = null
    this.default_server = null
    this.constants = {}
    this.features = {}
    this.extras = {}
  }
  getRelayContact() {
    return this.relay_contact
  }
  getMainContact() {
    return this.main_contact
  }
  getCDNContact() {
    return this.cdn_contact
  }
  getIdentity() {
    return this.identity
  }
  setIdentity(identity) {
    if (this.identity !== identity) {
      this.identity = identity
      this.getRelayContact().init(
        _.get(this.getConfig(), 'RELAY_HOST'),
        _.get(this.getConfig(), 'RELAY_PORT'),
        this.getHeaders(),
        { prefix: 'api/v3' }
      )
    }
    return this
  }
  getConstant(name) {
    return _.get(this.constants, name)
  }
  getAllConstants() {
    return this.constants
  }
  setConstants(constants) {
    this.constants = constants
    return this
  }
  setConstantValue(name, value) {
    this.constants = _.set(this.constants, name, value)
    this.notifyProviderListeners()
    return this
  }
  getFeature(name) {
    return _.get(this.features, name)
  }
  getAllFeatures() {
    return this.features
  }
  setFeatures(features) {
    this.features = features
    return this
  }
  setFeatureValue(name, value) {
    this.features = _.set(this.features, name, value)
    this.notifyProviderListeners()
    return this
  }
  getExtra(name) {
    return _.get(this.extras, name)
  }
  getAllExtras() {
    return this.extras
  }
  setExtras(extras) {
    this.extras = extras
    return this
  }
  getDefaultServer() {
    return this.default_server
  }
  setDefaultServer(default_server) {
    this.default_server = default_server
    this.main_contact.init(
      _.get(default_server, 'host'),
      _.get(default_server, 'port'),
      this.getHeaders(),
      { prefix: 'api/v3' }
    )
    return this
  }
  refreshCDNContact() {
    this.cdn_contact.init(this.getConstant('CDN_HOST'), 443, {}, { prefix: 'assets/v2' })
    return this
  }
  getConfig(key) {
    if (!!key)
      return _.get(this.config, key)
    return this.config
  }
  getDeviceInfo() {
    return this.device_info
  }
  getHeaders() {
    return {
      'X-FL-IDENTITY': this.getIdentity() || '',
      'X-QS-MODE': 'false',
      'X-FL-TYPE': _.get(this.getDeviceInfo(), 'type') || '',
      'X-FL-BUNDLE-ID': _.get(this.getConfig(), 'IMPERSONATE_BUNDLE_ID') || _.get(this.getDeviceInfo(), 'bundle_id') || '',
      'X-FL-VERSION': _.get(this.getDeviceInfo(), 'version_number') || '',
      'X-FL-PLATFORM-OS': _.get(this.getDeviceInfo(), 'platform_os') || '',
      'X-FL-PLATFORM-ID': _.get(this.getDeviceInfo(), 'platform_id') || '',
      'X-FL-PLATFORM-VERSION': _.get(this.getDeviceInfo(), 'platform_version') || '',
      'X-FL-IMPERSONATE-HOST': _.get(this.getConfig(), 'IMPERSONATE_HOST') || '',
      'Accept-Language': this.getLocale()
    }
  }
  getLocale() {
    return _.get(this.device_info, 'locale')
  }
  async rehydrateStorage() {
    const stored_config = await this.getProvider('storage').readPrefix('relay')
    if (!!_.get(stored_config, 'identity')) {
      this
        .setIdentity(_.get(stored_config, 'identity'))
        .setDefaultServer({
          host: _.get(this.getConfig(), 'BACKEND_HOST') || _.get(stored_config, 'default.host') || _.get(this.getConfig(), 'RELAY_HOST'),
          port: _.get(this.getConfig(), 'BACKEND_PORT') || _.get(stored_config, 'default.port') || _.get(this.getConfig(), 'RELAY_PORT')
        })
      if (!!_.get(stored_config, 'identity') && !_.isEmpty(_.get(stored_config, 'default')) && !!_.get(stored_config, 'fetchedAt') && moment.utc(_.get(stored_config, 'fetchedAt')).isAfter(moment.utc().subtract(1, 'hour'))) {
        this
          .setConstants(_.get(stored_config, 'config.CONSTANTS'))
          .setFeatures(_.get(stored_config, 'config.FEATURES'))
          .setExtras(_.pick(_.get(stored_config, 'config'), ['IVR_MAP', 'MUSIC_LIST', 'COLORS']))
          .refreshCDNContact()
        return true
      }
    }
    this.getRelayContact().init(
      _.get(this.getConfig(), 'RELAY_HOST'),
      _.get(this.getConfig(), 'RELAY_PORT'),
      this.getHeaders(),
      { prefix: 'api/v3' }
    )
    return false
  }
  async rehydrateServer() {
    const response = await this.getRelayContact().execute('GET', 'servers', {})
    this
      .setIdentity(_.get(response, 'identity'))
      .setDefaultServer({
        host: _.get(this.getConfig(), 'BACKEND_HOST') || _.get(response, 'servers.default.host') || _.get(this.getConfig(), 'RELAY_HOST'),
        port: _.get(this.getConfig(), 'BACKEND_PORT') || _.get(response, 'servers.default.port') || _.get(this.getConfig(), 'RELAY_PORT')
      })
      .setConstants(_.get(response, 'servers.default.config.CONSTANTS'))
      .setFeatures(_.get(response, 'servers.default.config.FEATURES'))
      .setExtras(_.pick(_.get(response, 'servers.default.config'), ['IVR_MAP', 'MUSIC_LIST', 'COLORS']))
      .refreshCDNContact()
    await this.updateStorage()
    this.notifyProviderListeners()
    return true
  }
  async updateStorage() {
    return this.getProvider('storage').setPrefix('relay', {
      config: _.merge({
        CONSTANTS: this.getAllConstants(),
        FEATURES: this.getAllFeatures()
      }, this.getAllExtras()),
      default: this.getDefaultServer(),
      identity: this.getIdentity(),
      fetchedAt: moment.utc().format()
    })
  }
  async init(config) {
    this.config = config.config
    this.device_info = config.info
    I18n.setLocale(this.getLocale())
    if (!await this.rehydrateStorage())
      await this.rehydrateServer()
    return super.init(true)
  }
}

export default Relay