import EventEmitter from 'eventemitter3'
import _ from 'lodash'
import React, { createContext, useContext, useCallback, useEffect, useMemo, useReducer, useLayoutEffect } from 'react'
import ViewSelector from '../../components/ViewSelector'
import { useGlobalFormFunction, useGlobalFormStatus, useNotification, useFormActionListener, useGlobalFormState, useGlobalKeyListener } from '../hooks'
import Callbacks from './callbacks'
import reducer from './reducer'
import Section, { SectionContext } from './section'
import SectionArray from './sectionarray'
const GlobalFormContext = createContext({})
const GlobalFormContextProvider = GlobalFormContext.Provider
const FormContext = createContext({})
const FormContextProvider = FormContext.Provider
const SectionContextProvider = SectionContext.Provider
const FormEmitter = new EventEmitter()
const FormListener = new EventEmitter()
const FormProvider = (props) => {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, {})
  const getPartialState = useCallback(Callbacks.getPartialStateHandler(state), [state])
  const getInjectedDispatch = useCallback(Callbacks.getInjectedDispatchHandler(dispatch), [dispatch])
  const getFormValues = useCallback((name) => _.get(getPartialState(name), 'values'), [getPartialState])
  const getFormState = useCallback((name) => _.get(getPartialState(name), 'state'), [getPartialState])
  const getFormMeta = useCallback((name) => _.get(getPartialState(name), 'meta'), [getPartialState])
  const emitter_instance = useMemo(() => FormEmitter, [])
  const listener_instance = useMemo(() => FormListener, [])
  return (
    <GlobalFormContextProvider value={{ emitter: emitter_instance, listener: listener_instance, getPartialState, getInjectedDispatch, getFormValues, getFormState, getFormMeta }}>
      <SectionContextProvider value={{ path: [] }}>
        {children}
      </SectionContextProvider>
    </GlobalFormContextProvider>
  )
}




const FormWrapper = (props) => {
  const { getInjectedDispatch, getFormValues, getFormState, getFormMeta, emitter, listener } = useContext(GlobalFormContext)
  const {
    name,
    initialValues,
    initialState,
    onSubmit,
    beforeSubmit = () => true,
    status: { isReady, isLoading } = {},
    destroyOnUnmount = true,
    submitChangesOnly = _.get(initialState, 'isManagingRecord'),
    includeReadOnly = false,
    submitOnEmpty = false,
    onSubmitSuccess: inputOnSubmitSuccess = _.noop,
    onSubmitFailed: inputOnSubmitFailed,
    fastMode = false,
    submitOnChange = false,
    submitOnEnter = false,
    submitOnReset = false,
    submitOnMount = false,
    submitWatchers = [],
    closeOnSuccess = true,
    closeOnFail = false,
    getSuccessNotification = _.noop,
    getFailedNotification = _.noop,
    forwardValues = false,
    forwardKey,
    enabled_fields,
    children,
    disableParentSubmitListener = false,
    disableParentDisabledListener = false,
    skipFormError = false
  } = props
  const { isMounted, isRemoved, isSubmitted, isSubmitting, isReadOnly, isEditable } = useGlobalFormStatus(name)
  const { openNotification } = useNotification()

  const status = useMemo(() => ({ isReady: isReady && !isRemoved, isLoading, isMounted, isRemoved, isSubmitted, isSubmitting, isReadOnly, isEditable: isEditable && !isRemoved }), [isReady, isLoading, isMounted, isRemoved, isSubmitted, isSubmitting, isReadOnly, isEditable])
  const onModeChange = useGlobalFormFunction(name, 'onModeChange')
  const [, setReadOnly] = useGlobalFormState(name, 'isReadOnly')
  const dispatch = useCallback(getInjectedDispatch(name), [getInjectedDispatch, name])
  const stateValues = useMemo(() => getFormValues(name), [getFormValues, name])
  const formState = useMemo(() => getFormState(name), [getFormState, name])
  const meta = useMemo(() => getFormMeta(name), [getFormMeta, name])
  const isFormMounted = !!isMounted && !_.isEmpty(meta)

  const values = useMemo(() => !isMounted ? initialValues : stateValues, [stateValues, initialValues, isMounted])
  const activeState = useMemo(() => !!isReadOnly ? initialState : formState, [formState, initialState, isReadOnly])
  const isFieldWritable = useCallback(field => _.isEmpty(enabled_fields) || !!_.find(enabled_fields, (f) => f === field), [(enabled_fields || []).join(',')])
  const reset = useCallback(Callbacks.resetHandler(dispatch, listener, name, initialValues, initialState), [dispatch, listener, name, initialValues, initialState])
  const onSubmitSuccess = useCallback(
    Callbacks.onSubmitResultHandler('success', name, dispatch, inputOnSubmitSuccess, closeOnSuccess, openNotification, getSuccessNotification, listener),
    [dispatch, inputOnSubmitSuccess, closeOnSuccess, name, getSuccessNotification]
  )
  const onSubmitFailed = useCallback(
    Callbacks.onSubmitResultHandler('failed', name, dispatch, inputOnSubmitFailed, closeOnFail, openNotification, getFailedNotification, listener),
    [dispatch, inputOnSubmitFailed, closeOnFail, name, getFailedNotification]
  )
  const onFormSubmit = useCallback(
    Callbacks.onFormSubmitHandler(
      onSubmit,
      beforeSubmit,
      skipFormError,
      { values, meta, state: getFormState(name) },
      { submitOnEmpty, submitChangesOnly, includeReadOnly },
      { dispatch, onSubmitSuccess, onSubmitFailed }
    ),
    [name, onSubmit, beforeSubmit, skipFormError, values, meta, getFormState, submitOnEmpty, submitChangesOnly, includeReadOnly, dispatch, onSubmitSuccess, onSubmitFailed]
  )
  const onEmitterAction = useCallback(Callbacks.onEmitterActionHandler(onFormSubmit, reset, submitOnChange), [onFormSubmit, reset, submitOnChange])

  useFormActionListener('submitted', (e) => {
    e?.data?.result_type === 'success' && onFormSubmit(e)
  }, { disabled: disableParentSubmitListener })
  useFormActionListener('readOnly', e => {
    setReadOnly(e.data.isReadOnly)
  }, { disabled: disableParentDisabledListener || !status?.isReady })


  useGlobalKeyListener(onFormSubmit, ['Enter'], { disabled: !isFormMounted || !submitOnEnter })

  useEffect(() => {
    !isRemoved && listener.emit(name, { action: 'readOnly', data: { isReadOnly } })
  }, [isReadOnly])

  useEffect(() => {
    !!isFormMounted && !!submitOnMount && onFormSubmit()
  }, _.flatten([isFormMounted, submitWatchers]))

  useEffect(() => {
    if (!isReadOnly && !!isReady && !isMounted)
      onModeChange(initialValues, activeState || initialState)
  }, [isReadOnly, isReady])

  useEffect(() => {
    if (!!isReadOnly && !!isMounted)
      dispatch({ type: 'CLEAR_FORM' })
  }, [isReadOnly])

  useEffect(() => {
    return () => !!destroyOnUnmount && !!isReady && dispatch({ type: 'CLEAR' })
  }, [isReady])

  const forwardProps = !!forwardValues ? { [forwardKey]: values } : {}
  useLayoutEffect(() => {
    emitter.on(name, onEmitterAction)
    return () => {
      emitter.off(name, onEmitterAction)
    }
  }, [onEmitterAction])

  const contextValues = useMemo(() => ({
    name,
    values,
    status,
    props: _.omit(props, ['children']),
    functions: {
      isFieldWritable,
      reset
    }
  }), [name, values, status, props, isFieldWritable, reset])

  return (
    <FormContextProvider value={contextValues}>
      <ViewSelector {...forwardProps}>{children}</ViewSelector>
    </FormContextProvider>
  )
}

const Form = React.memo(FormWrapper)


export {
  GlobalFormContext,
  FormContext,
  Form,
  Section,
  SectionArray,
  FormEmitter,
  FormListener
}
export default React.memo(FormProvider)

